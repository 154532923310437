.prizes-wrapper {
    width: 100%;
    overflow-y: scroll;
}

.prizes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    padding: 0 2rem 4rem;
}

.prizes-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;

    padding: 0 12rem;
}

.membership-tier-section {
    display: flex;
    gap: 1rem;
}

.membership-tier-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    flex: 1 1 0;

    border-radius: 1rem;
    border: 2px solid var(--translucent-border);

    padding: 1rem 2rem;

    cursor: pointer;
    transition-duration: 250ms;
}

.membership-card-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
}

.card-title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    min-height: 6rem;
}

.features-list {
    display: flex;
    flex-direction: column;
    align-self: start;
}

.features-list li {
    padding-bottom: 0.25rem;
}

.features-list .list-check-item {
    display: flex;
    gap: 0.5rem;
    padding-bottom: 0.25rem;
}

.features-list .list-x-item {
    display: flex;
    gap: 0.5rem;
    padding-bottom: 0.25rem;
    color: var(--text-color-dark);
}

.prize-list li {
    padding-left: 1.5rem;
}

.prize-list li:before {
    content: '\2022';
    padding-right: 0.5rem;
}

.basic:hover {
    cursor: default;
    border: 2px solid var(--gray);
}

.premium:hover {
    border: 2px solid var(--silver);
}

.founder:hover {
    border: 2px solid var(--gold);
}

.payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    padding: 1rem 2rem;

    border: 2px solid var(--translucent-border);
    border-radius: 1rem;
    width: 100%;
}

.animated-border {
    animation: flashBorder 2s ease-out infinite;
}

@keyframes flashBorder {
    0%, 50%, 100% {
        border-color: var(--translucent-border);
        opacity: 1;
    }
    25%, 75% {
        border-color: var(--sea-salt);
        opacity: 1;
    }
}

.payment-input {
    background: hsl(0 0 20);
    color: inherit;
    border: none;
    border-radius: 0.375rem;
    padding: 0.375rem 0.5rem;
    font: inherit;
    outline: inherit;
    font-size: 1.5rem;
}

.suggested-options {
    display: flex;
    gap: 1rem;
}

.payment-option-button {
    background: var(--background-solid-dark);
    border-radius: 1rem;
    border: 1px solid var(--translucent-border);
    padding: 1rem 2rem;
}

.payment-option-button:hover, .payment-option-button:focus {

    background: #232323;
}

@media (max-width: 1180px) {
    .prizes-title {
        padding: 0 8rem;
    }
}

@media (max-width: 768px) {
    .prizes-title {
        padding: 0;
    }

    .membership-tier-section {
        flex-direction: column;
    }

    .basic {
        cursor: default;
        border: 2px solid var(--gray);
    }

    .premium {
        border: 2px solid var(--silver);
    }

    .founder {
        border: 2px solid var(--gold);
    }
}

@media (max-width: 480px) {
    .payment-option-button {
        padding: 0.5rem 1rem;
    }
}