/* ===== WRAPPER CONTAINER ===== */
.crystal-ball-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 100%;
}


/* ===== NAVIGATION ===== */

.back-button,
.next-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin: 2rem;
    padding: 1rem;
    background: var(--background-solid-dark);

    font-size: 1.5rem;
}

.back-next-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.back-next-container .back-button {
    display: none;
}

.question-counter {
    padding-top: 1rem;
}


/* ===== PICK CARD STYLES ===== */

.card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.pick-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0.625rem;

    height: 50vh;
    aspect-ratio: 1;

    background: var(--background-solid-dark);
    border: 1px solid hsl(from var(--text-color-light) h s l / 20%);
    overflow: hidden;

    cursor: pointer;

    max-width: 100%;
}

.pick-card.champion-card {
    aspect-ratio: 16 / 9;
}

.pick-card img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.pick-card.item-card img, .pick-card.dragon-card img {
    filter: blur(10px);
}

.pick-card.item-card .item-center-icon, .pick-card.dragon-card .item-center-icon {
    position: absolute;
    top: calc(50% - 5rem);
    right: calc(50% - 3rem);


    z-index: 2;
    filter: none;

    height: 6rem;
    width: 6rem;
    padding: 0.375rem;

    background: var(--background-solid-dark);
    border: 1px solid var(--text-color-light);
}

.question-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    background: var(--black);
    z-index: 1;
}

.question-bar p {
    padding: 0.875rem;
}

.question-bar .pick-button {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    font-size: 1.5rem;
    font-weight: 600;
    white-space: nowrap;

    height: 100%;
    padding: 0.875rem;
    background-color: var(--auburn);
}

#point-value-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    padding: 1rem;
}

#point-circle {
    height: 2rem;
    width: 2rem;
    background-color: var(--gold);
    border-radius: 50%;
}

#point-value {
    font-size: 2rem;
    color: var(--gold);
}

/* ===== PICK MODAL STYLES ===== */

.pick-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.pick-modal.open {
    display: block;
    backdrop-filter: blur(5px);

    z-index: 999;
}

.pick-modal-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    background-color: var(--black);
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    width: 80%; /* Could be more or less, depending on screen size */
    max-height: 80%;

    cursor: default;

}

.close-button {
    color: var(--text-color-light);
    cursor: pointer;
    font-size: 1rem;
}

.modal-title {
    display: flex;
    justify-content: space-between;
}

.pick-modal-content input {
    background: var(--background-solid-dark);
    color: inherit;
    border: none;
    border-radius: 0.375rem;
    padding: 0.375rem 0.5rem;
    font: inherit;
    outline: inherit;
}

.pick-modal-content .numerical-input {
    font-size: 3rem;
}

.modal-dialog {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));

    padding: 1.25rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.375rem;
    border: 1px solid transparent;

    padding: 1.5rem;
}

.icon.active {
    background: var(--light-black);
    border: 1px solid var(--translucent-border);
}

.icon:hover {
    background: var(--light-black);
    cursor: pointer;
}

.icon img {
    object-fit: contain;
    aspect-ratio: 1;
    width: 100%;
}

#submit-button {
    font-size: 2rem;
    padding: 1rem;
    text-transform: uppercase;
    background: var(--auburn);
}

#submit-button.disabled {
    background: var(--light-black);
    cursor: default;
}

/* ===== BACKGROUND EFFECTS ===== */

#ellipse {
    position: absolute;
    left: 0;
    width: 100%;
    height: 25%;
    background: var(--auburn);
    opacity: 80%;
    filter: blur(40px);
    border-radius: 9999px / 999px;
    z-index: -99;
}

#scroll-text-container {
    display: flex;
    position: absolute;
    left: 0;
    overflow: hidden;
    z-index: -2;
    width: 100%;
}

.scroll-text {
    padding: 3rem;

    font-family: 'Bebas Neue';
    font-size: 25rem;
    color: transparent;
    white-space: nowrap;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--text-color-light);


    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: my-animation 20s linear infinite;
    -webkit-animation: my-animation 20s linear infinite;
    animation: my-animation 20s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
    from { -moz-transform: translateX(0); }
    to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(0); }
    to { -webkit-transform: translateX(-100%); }
}

@keyframes my-animation {
    from {
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}


/* ===== MEDIA QUERIES ===== */

@media (max-width: 1400px) {
    .modal-dialog {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}

@media (max-width: 1024px) {
    .crystal-ball-wrapper {
        flex-direction: column;
    }

    .back-button {
        display: none;
    }

    .back-next-container .back-button {
        display: flex;
    }

    .modal-dialog {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    #ellipse {
        display: none;
    }

    #scroll-text-container {
        display: none;
    }
}

@media (max-width: 480px) {
    .pick-card {
        max-width: 100vw;
    }

    .modal-dialog {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .icon {
        padding: 0.75rem;
    }

    .question-bar .pick-button {
        font-size: 1rem;
        padding: 0.625rem 0.875rem;
    }

    .question-bar p {
        font-size: 0.875rem;
        padding: 0.625rem 0.875rem;
    }

    .back-button,
    .next-button {
        font-size: 1rem;
        margin: 1rem 2rem;
    }
}