#leaderboard-container {
    padding-right: 2rem;
    padding-left: 2rem;
    overflow: hidden;
}

.leaderboard-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: scroll;

    max-width: 100%;
}