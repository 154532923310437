.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    background: var(--black);
    width: 100%;
    padding: 2.5rem 5rem;

    overflow-y: scroll;
}

.gallery-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: 100%;
}

.gallery-container .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}

#single-card-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    position: fixed;
    bottom: 4rem;
    z-index: 2;

    border: 1px solid var(--translucent-border);
    border-radius: 999px;

    background: var(--background-solid-dark);
    padding: 0.75rem 3rem;
}

@media (max-width: 1024px) {
    .gallery-container {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
    }

    .gallery-container .cards {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}