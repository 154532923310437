.leaderboard {
    display: flex;
    flex-direction: column;
    background: var(--black);
    width: 60rem;
    max-width: 100%;

    padding-bottom: 2rem;
}

.leaderboard-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 1.125rem;
}

.leaderboard-user-info {
    display: flex;
    align-items: center;
}

.leaderboard-profile {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.profile-image {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}