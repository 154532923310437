.stages-list {
    display: flex;
    border: 1px solid var(--translucent-border);
    border-radius: 999rem;
    background: var(--black);
    overflow: hidden;
}

.stages-list li {
    padding: 0.375rem 1.25rem;
}

.stages-list li a {
    color: var(--text-color-dark);
}

.stages-list li a.active {
    color: var(--text-color-light);
}

.stages-list li:has(> a.active) {
    background: linear-gradient(to left, hsla(20, 5%, 24%, 1), hsla(20, 4%, 11%, 1));
}

@media (max-width: 480px) {
    .stages-list li a {
        font-size: 0.75rem;
        white-space: nowrap;
    }
}