.tournament-dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 3rem;
    cursor: pointer;

    --dropdown-height: 100%;
}

.tournament-dropdown .dropdown-title {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
}

.tournament-dropdown:has(> .dropdown-options.open) .dropdown-title {
    background: var(--black);
    border: 1px solid var(--translucent-border);
    border-radius: 0.5rem;
}

.tournament-dropdown .dropdown-options {
    display: none;
}

.tournament-dropdown .dropdown-options.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(var(--dropdown-height) + 0.375rem);
    width: 100%;

    background: var(--black);
    border: 1px solid var(--translucent-border);
    border-radius: 0.5rem;
    z-index: 9;
    overflow: hidden;
}

.tournament-dropdown .dropdown-options li {
    padding: 0.5rem 1rem;
}

.tournament-dropdown .dropdown-options li:hover {
    background-color: var(--light-black);
}

.tournament-dropdown .dropdown-options li.disabled h3{
    font-size: 1rem;
    color: var(--text-color-dark);
}


.tournament-dropdown .dropdown-options li.disabled:hover {
    cursor: default;
    background: initial;
}