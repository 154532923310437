.home-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    width: 100%;
    padding: 5rem 7.5rem 0 7.5rem;
}

.home-title {
    display: flex;
    flex-direction: column;
}

.text-decoration {
    color: var(--gold);
    font-size: 2rem;
    font-weight: 200;
}

.home-container h1 {
    align-self: flex-start;
    transform: translateX(-2%);
}

.large-button {
    font-size: 1.5rem;
    font-weight: 600;

    padding: 1.5rem 3rem;
}

.cta-section {
    display: flex;
    flex-direction: column;

    align-self: start;

    padding: 2rem 4rem;
    border-radius: 1rem;
    background: var(--background-solid-dark);

    gap: 2rem;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

#benefits-list {
    padding-top: 2rem;

    list-style: circle;
}

#benefits-list li {
    font-size: 1.125rem;
    padding-bottom: 1.5rem;
}

@media (max-width: 1180px) {
    .home-container {
        padding: 4rem;
    }

    .text-decoration {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .home-container {
        padding: 2rem;
    }

    .home-subtitle {
        padding-top: 1rem;
    }

    .text-decoration {
        font-size: 1rem;
    }

    .large-button {
        font-size: 1rem;
        font-weight: 600;

        padding: 1rem 2rem;
    }

    .cta-section {
        padding: 2rem;
        font-size: 0.875rem;
    }

    #why-choose-idols h4 {
        font-size: 1.5rem;
    }

    #benefits-list {
        padding-top: 1.25rem;

        list-style: circle;
    }

    #benefits-list li {
        font-size: 0.875rem;
        padding-bottom: 0.75rem;
    }
}