.toast {
    display: flex;
    align-items: center;
    gap: 1rem;

    position: relative;

    max-width: 30rem;

    padding: 1rem;

    background: var(--background-solid-dark);
    border: 1px solid var(--translucent-border);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    animation: slideIn 0.3s ease-out;
    cursor: pointer;

    overflow: hidden;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
        transform: translateX(100%);
        height: 0;
        margin: 0;
        padding: 0;
    }
}

.success::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px; /* Stripe width */
    background-color: #478547; /* Green */
}

.failure::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px; /* Stripe width */
    background-color: hsl(from var(--auburn) h 55 45);
}

.warning::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px; /* Stripe width */
    background-color: var(--gold); /* Yellow */
}

.info::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px; /* Stripe width */
    background-color: #1b5094; /* Blue */
}

@media (max-width: 480px) {
    .toast {
        max-width: 90vw;
    }
}