.podium {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    padding: 1rem 2rem;
    background: var(--black);
}

.podium-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.profile-pic {
    position: relative;
    width: 7rem;
    height: 7rem;
    border: 0.25rem solid var(--gold);
    border-radius: 50%;
    overflow: hidden;
}

.profile-pic.first {
    width: 10rem;
    height: 10rem;
}

.profile-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.placement {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--gold);
    color: var(--black);
    text-align: center;
    padding: 3px;
    font-size: 12px;
}

@media (max-width: 480px) {

    .podium {
        width: 100%;
    }

    .profile-pic {
        width: 5rem;
        height: 5rem;
    }

    .profile-pic.first {
        width: 6rem;
        height: 6rem;
    }

}