.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    text-align: center;

    padding: 2rem;

    background: var(--black);
    border-top: 1px solid var(--translucent-border);
}

#terms-modal {
    display: none; /* Hide by default */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */
}

#terms-modal.open {
    display: flex;
}

.terms-modal-dialog {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;

    background-color: var(--black);
    margin: 15% auto;
    padding: 20px;
    width: 80%;
    max-width: 600px; /* Limit maximum width */
    border: 1px solid var(--translucent-border);
}

.terms-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    overflow-y: scroll;
}

.close {
    color: var(--text-color-light);
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: var(--text-color-dark);
    text-decoration: none;
    cursor: pointer;
}

#tos-button:hover {
    color: var(--text-color-dark);
}