@import './reset.css';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/* This stylesheet contains styles that are component-independent and are used throughout the entire application. */


:root {
    /* ===== GLOBAL VARIABLES ===== */

    /* Solid Colors */
    --sea-salt: hsl(20 26 93);
    --silver: hsl(210 18 87);
    --gold: hsl(40 64 69);
    --bronze: hsl(28 53 44);
    --auburn: hsl(0 61 25);
    --gray:	hsl(20 2 49);
    --light-black: hsl(0 0 13);
    --smoky-black: hsl(0 0 8);
    --black: hsl(0 0 6);

    /* Gradients */
    --background-gradient: linear-gradient(to bottom, var(--smoky-black), var(--light-black));

    /* Functional Colors */
    --text-color-light: var(--sea-salt);
    --text-color-dark: var(--gray);
    --background-solid-dark: var(--smoky-black);
    --translucent-border: hsl(from var(--sea-salt) h s l / 10%);

    /* Measurements */
    --navbar-height: 4rem;
    --navbar-height-mobile: 3rem;


    /* ===== ROOT ELEMENT STYLES ===== */

    background-image: var(--background-gradient);
    width: 100vw;
    height: 100vh;


    /* ===== DEFAULT FONT STYLES ===== */

    font-size: 16px;
    font-weight: 400;
    font-family: "Montserrat",sans-serif;

    /* ===== OTHER STYLING ===== */

    ::-moz-selection { /* Code for Firefox */
        color: var(--black);
        background: var(--gold);
    }

    ::selection {
        color: var(--black);
        background: var(--gold);
    }

    overflow-x: hidden;
}

body {

}

/* ===== TEXT STYLES ===== */

h1, h2, h3, h4, h5, h6,
div, span, p, a, li, button {
    color: var(--text-color-light);
}

h1 {
    font-size: 10rem;
    font-weight: 700;
}

h2 {
    font-size: 3rem;
    font-weight: 600;
}

h3 {
    font-size: 2.5rem;
    font-weight: 600;
}

h4 {
    font-size: 2rem;
    font-weight: 600;
}

a {
    text-decoration: none;
}

a:hover {
    color: var(--text-color-dark);
}

a.active {
    font-weight: 600;
}

b {
    font-weight: 600;
}

i {
    font-style: italic;
}


/* ===== CONTAINERS ===== */

/* Container for page content, leaving space for the navbar */
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding-top: calc(var(--navbar-height) + 2rem);

    height: 100vh;
}


/* ===== UTILITY CLASSES ===== */

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.primary-button {
    background: var(--gold);
    color: var(--black);
    padding: 0.625rem 2rem;

    border-radius: 99rem;
}

.primary-button:hover {
    background: hsl(from var(--gold) h 40 50);
    color: var(--black);
}

.red-button {
    background: var(--auburn);
    padding: 0.625rem 2rem;

    border-radius: 99rem;
}

.red-button:hover {
    background: hsl(from var(--auburn) h 60 20);
}


/* ===== RESPONSIVE STYLES ===== */

@media (max-width: 1180px) {
    h1 {
        font-size: 6rem;
    }
}

@media (max-width: 480px) {
    .primary-button {
        padding: 0.375rem 1rem;
    }

    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.625rem;
    }

    h4 {
        font-size: 1rem;
    }

    .content {
        gap: 1.5rem;
        padding-top: calc(var(--navbar-height-mobile) + 1.5rem);
    }
}


/* ===== SCROLLBAR RESTYLING ===== */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--background-solid-dark);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Dark Gray */
}