.playins-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10rem;

    width: 100%;
    padding: 4rem;

    background: var(--black);
    border-top: 1px solid var(--translucent-border);

    flex-grow: 1;
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.double-elim-bracket {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    width: 100%;
}

.bracket-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.round-labels {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.round-label {
    flex: 1 1 0;
    text-align: center;
}

.bracket {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;

    align-items: center;
}

.round {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    flex: 1 1 0; /* Ensures it is the same width as sibling elements */
}

.match {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 0; /* Ensures it is the same width as sibling elements */
}

.match .team, .match .advance {
    display: flex;
    align-items: center;
    gap: 1rem;

    min-height: 6.125rem;
    font-size: 1.5rem;
    padding: 1rem 1rem;

    border: 1px solid var(--translucent-border);
}

.match .team:nth-child(2)::after {
    content: 'vs';

    position: absolute;
    font-size: 1.25rem;

    color: var(--text-color-light);
    background: var(--black);
    padding: 0.125rem 1.25rem;

    border: 1px solid var(--translucent-border);
    border-radius: 99rem;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.match .team:hover {
    background: var(--light-black);
    cursor: pointer;
}

.team img, .advance img {
    width: 4rem;
    height: 4rem;
}

.subtitle {
    position: absolute;
    bottom: 100%;
    white-space: nowrap;

    padding-bottom: 0.5rem;
}

.team.winner {
    color: var(--gold);
    border: 1px solid var(--sea-salt);

    background: var(--background-solid-dark);
}

.team.loser {
    color: var(--text-color-dark);
}

@media (max-width: 1024px) {
    .round-label {
        font-size: 0.75rem;
        flex: 1 1 0;
        text-align: center;
    }

    .match .team:nth-child(2)::after {
        font-size: 0.75rem;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .playins-container {
        padding: 2rem;
        overflow: auto;
    }

    .match .team, .match .advance {
        min-height: 4.125rem;
        font-size: 0.875rem;
    }

    .team img, .advance img {
        width: 2rem;
        height: 2rem;
    }

    .subtitle {
        font-size: 0.75rem;
    }
}

@media (max-width: 480px) {
    .double-elim-bracket {
        width: 200%;
    }
}