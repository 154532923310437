.profile-wrapper-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    margin: 2rem 7.5rem;
    padding: 4rem 2rem;
    max-width: 100%;

    border-radius: 1rem;
    background: var(--black);
}

.profile-container {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.profile-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    max-width: 10rem;
}

.profile-picture-container button:hover {
    color: var(--text-color-dark);
}

.profile-picture {
    position: relative;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--translucent-border);
    cursor: pointer;
}

.profile-picture img {
    object-fit: cover;

    width: 100%;
    height: 100%;
}

.profile-picture::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4); /* Dark overlay */
    opacity: 0; /* Start fully transparent */
    transition: opacity 50ms ease; /* Smooth transition for the overlay */
}

.profile-picture:hover::after {
    cursor: pointer;
    opacity: 1; /* Fully visible on hover */
}


.edit-icon {
    position: absolute;
    top: 50%; /* Center the icon vertically */
    left: 50%; /* Center the icon horizontally */
    transform: translate(-50%, -50%); /* Ensure it's centered perfectly */
    color: white; /* Adjust color based on your design */
}

.profile-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.member-status {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.display-name {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.display-name input {
    background: hsl(0 0 20);
    color: inherit;
    border: none;
    border-radius: 0.375rem;
    padding: 0.375rem 0.5rem;
    font: inherit;
    outline: inherit;
    font-size: 2.5rem;
}

.display-name button:hover {
    opacity: 50%;
}

.past-tournaments {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.past-tournaments h3 {
    text-align: center;
}

@media (max-width: 480px) {

    .profile-wrapper-box {
        gap: 2rem;

        margin: 0 2rem;
        padding: 2rem;
    }

    .profile-container {
        width: 100%;
        gap: 1rem;
    }

    .profile-picture-container {
        max-width: 6rem;
    }

    .profile-picture-container button {
        font-size: 0.75rem;
    }

    .profile-picture {
        width: 5rem;
        height: 5rem;
    }

    .display-name input {
        width: 100px;
        font-size: 1rem;
    }

    .past-tournaments {
        padding: 0 2rem;
        gap: 1rem;
    }

    .past-tournaments h3 {
        text-align: start;
    }

}