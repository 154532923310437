.navbar {
    display: flex;
    justify-content: start;
    gap: 1rem;
    padding: 1rem 7.5rem;
    background: hsl(from var(--background-solid-dark) h s l / 10%);
    backdrop-filter: blur(20px);
    min-height: var(--navbar-height);
    z-index: 1;
}

.navbar-left {
    display: flex;
    gap: 4rem;
    list-style: none;
}

.navbar-left li {
    display: flex;
    align-items: center;
}

.navbar-right {
    position: absolute;
    display: flex;
    align-items: center;
    right: 7.5rem;
}

.navbar-right li {
    display: flex;
    align-items: center;
    height: 2rem;
}

nav .menu-toggle{
    display: none;
}

@media (max-width: 1024px) {
    .navbar {
        border-bottom: 1px solid var(--translucent-border);
        padding: 0.5rem 1rem;
        align-items: start;
        flex-direction: column;
        min-height: var(--navbar-height-mobile);
    }

    .navbar-right {
        right: 1rem;
        top: 0.5rem;
        height: 2rem;
    }

    .navbar-left {
        display: none;
        flex-direction: column;
        gap: 1rem;
        align-items: start;
    }

    .navbar-left li {
        display: block;
    }

    .navbar-left.open {
        display: flex;
    }

    nav .menu-toggle {
        display: block;
        height: 2rem;
    }

    .navbar-logo {
        display: none;
    }
}