.combined-title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    padding: 0 2rem;
}

@media (max-width: 480px) {
    .combined-title-section {
        gap: 0.375rem;
    }
}