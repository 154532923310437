.gallery-card-wrapper {
    display: flex;
    align-items: center;

    aspect-ratio: 16 / 9;

    overflow: hidden;
    cursor: pointer;
}

.gallery-card {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border: 1px solid var(--sea-salt);

    background-image: linear-gradient(to right, #151515, #292726);

    width: 100%;
    height: 100%;
}

.gallery-card .question-bar {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-right: 1rem;

    min-height: 2.5rem;
}

.gallery-card .question-bar .point-value-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.gallery-card .question-bar .point-value-container #point-circle {
    display: flex;
    height: 0.875rem;
    width: 0.875rem;
}

.gallery-card .question-bar .point-value-container #point-value {
    font-size: 1rem;
}

.gallery-card .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    /* Decrease the opacity */
    background-color: rgba(0,0,0,0.2);
    background-blend-mode: darken;


    overflow: hidden;
}

.title .title-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    text-align: center;

    font-family: 'Bebas Neue';
    font-size: 6rem;

    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--text-color-light);

    width: 100%;
    height: 100%;
}

.gallery-card .pick-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.625rem;

    height: 2.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;

    background-color: var(--auburn);
}

@media (max-width: 480px) {
    .title .title-content {
        font-size: 4rem;
    }
}